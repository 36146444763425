import React, {useState} from "react"


const FAQ = ({ question, answer, style}) => {

    const [open, setOpen] = useState(false);

    function doClick() {
        setOpen(open ? false : true);
        console.log("clicked");
    }

    return (
      <div className={'faq ' + (open ? "open" : "")} onClick={doClick} style={style}>
          <h3>Q. {question}</h3>
          <div className='answer'>
            { answer }
          </div>

      </div>         
    )
}
  
export default FAQ