import * as React from "react"
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import MainArea from  '../components/MainArea';
import NextBanner from "../components/NextBanner";
import FAQ from '../components/FAQ';
import {Helmet} from 'react-helmet';
import { StaticImage } from "gatsby-plugin-image";


// markup
const FAQsPage = () => {
    return (
        <Layout>

            <Helmet>
                <title>FAQs | Bluetree Group</title>
                <meta name="description" content="Frequently asked questions" />
            </Helmet>             
            <Banner title="FAQs">
                <StaticImage src={"../assets/content/777A9198.JPG"} alt="Putting masks in a box" />                                
            </Banner>    
            <MainArea>
                <FAQ 
                    question="When's the best time to apply? Any opening or closing dates?"
                    answer={
                        <>
                            <p>A: You can apply for the graduate management programme at any time. However, we do have fixed closing dates for our next Summer 2023 programme:</p>
                            <p><strong>Summer programme - Starting Summer 2023</strong></p>
                            <p>January 2023 - Applications close</p>
                            <p>February 2023 - Assessment day / interviews</p>
                        </>
                    } 
                />
                <FAQ 
                    question="I don’t have a business related degree, and I haven’t got experience in management. Is that ok?"
                    answer={
                        <>
                            <p>A: We’re looking for talented graduates who demonstrate our values and who want an exciting career in manufacturing. We don’t mind what degree you studied. Our only requirement is that you achieved a 2:1 or higher. We’ll provide all the training, support, and opportunities you will need to fulfil your potential. </p>
                        </>
                    } 
                />    
                <FAQ 
                    question="If I’m not successful this time round, could I reapply?"
                    answer={
                        <>
                            <p>A: Yes. Provided you meet the required qualifications and apply before the deadline for the next cohort, you are welcome to submit a new application.</p>
                        </>
                    } 
                />                        
                <FAQ 
                    question="When and where will the assessments take place?"
                    answer={
                        <>
                            <p>A: Assessments will take place at a venue near the Sheffield area that we use for our leadership training.</p>
                            <p>Assessment days are:</p>
                            <p>Summer 2023 programme: February 2023 </p>                           
                        </>
                    } 
                />                 
                <FAQ 
                    question="Will you pay for my travel/ accommodation to attend the assessment day?"
                    answer={
                        <>
                            <p>A: Yes, reasonable travel costs will be covered to attend the assessment day. If required, then accommodation will be provided.</p>
                        </>
                    } 
                />     
                <FAQ 
                    question="What will the assessments involve? Do I need to prepare?"
                    answer={
                        <>
                            <p>A: Prepare to be challenged. We are looking for talented graduates who demonstrate our values, so we’ll be running a number of exercises (group and individual) to identify the best candidates. You’ll also be interviewed by our directors and have the opportunity to ask questions and visit Bluetree Group’s facilities.</p>
                            <p>A detailed assessment day schedule will be shared in advance with successful candidates.</p>
                        </>
                    } 
                />     
                <FAQ 
                    question="Will I need to relocate?"
                    answer={
                        <>
                            <p>A: Bluetree Group is situated in the heartland of the northern powerhouse, close to Sheffield, Leeds and Doncaster, where many of our colleagues live.</p>
                            <p>As a manufacturing business, our colleagues work on site. We therefore recommend that you live close enough to enable you to have a comfortable daily commute.</p>
                        </>
                    } 
                />          
                <FAQ 
                    question="What will my working hours typically be?"
                    answer={
                        <>
                            <p>A: During the graduate management programme, core activity will take place between 09.00 – 17.30.</p>
                            <p>However, when you are on secondments, or managing projects, you may need to adopt a shift working pattern, depending on your role.</p>
                        </>
                    } 
                />
                <FAQ 
                    question="How many graduates are you looking to recruit?"
                    answer={
                        <>
                            <p>A: We are looking to recruit talented graduates who demonstrate our values. We do not have a fixed number for this year’s programme, as we want to attract the best candidates.</p>
                        </>
                    } 
                />        
                <FAQ 
                    question="I’ve already graduated, can I still apply?"
                    answer={
                        <>
                            <p>A: Yes. We know that existing post-graduates may not have found the right job to meet their talents and expectations yet, so we’re not just looking for applications from 2022 graduates. If you have graduated since 2019, and are looking to accelerate your career, then you are welcome to apply.</p>
                        </>
                    } 
                />     
                <FAQ 
                    question="I am an international graduate, can I apply?"
                    answer={
                        <>
                            <p>A: Yes. Provided you meet the relevant criteria, you are welcome to apply. To join the graduate management programme, you will need:</p>
                            <ul>
                                <li>a 2:1 degree qualification</li>
                                <li>the right to work in the UK</li>
                                <li>fluent English speaker</li>
                                <li>to be willing and able to relocate to the Sheffield/Leeds area</li>
                                <li>to attend the February assessment day for the Summer programme</li>
                                <li>availability to start on:
                                    <ul>
                                        <li>Summer 2023</li>    
                                   </ul>
                                </li>

                            </ul>

                        </>
                    } 
                />     
                <FAQ 
                    question="Can I work from home?"
                    answer={
                        <>
                            <p>A: As a manufacturing business, our colleagues mainly work on site. There may be occasions when home working is acceptable and can be agreed with your manager, but you should assume that you will be working at our main facilities. </p>
                        </>
                    } 
                />     
                <FAQ 
                    question="What happens after the Graduate Management Programme ends? Can I choose which role I take?"
                    answer={
                        <>
                            <p>A: At the end of the graduate management programme, there will be a final development away day, providing the opportunity to share and receive feedback on learnings, obstacles, and challenges throughout the year. </p>
                            <p>You’ll have 1-2-1 meetings to discuss your future role within Bluetree Group and identify the best opportunities available in the business for you, which are appropriate to the skills you’ve developed and you’re keen to explore further. </p>
                            <p>Following completion of the graduate management programme, participants can apply to join the next cohort for Advanced Leadership, to ensure continuous professional development. </p>
                        </>
                    } 
                />         
                <NextBanner destination="/register">
                    <strong>Next:</strong> Register your interest
                </NextBanner>

            </MainArea>
        </Layout>     
    )
  }
  
  export default FAQsPage
  